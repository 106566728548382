<template>
  <div class="w-full py-8">
    <ExportSettings />
  </div>
</template>

<script>
import ExportSettings from '@components/Settings/Export/ExportSettings'

export default {
  components: {
    ExportSettings
  }
}
</script>
