<template>
  <BaseCard>
    <header class="sm:flex items-center justify-between">
      <div class="flex items-center">
        <span
          class="flex flex-col justify-center items-center h-10 w-10 rounded-full mr-4 bg-secondary text-white pt-1"
        >
          <Icon
            icon="Team"
            width="24px"
            height="24px"
            view-box="0 0 80.13 80.13"
          >
            <Team />
          </Icon>
        </span>
        <h2 class="text-lg">
          Exports
        </h2>
      </div>
    </header>

    <ExportTable class="mt-8" />
  </BaseCard>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Team from '@components/Icons/Team'
import ExportTable from '@components/Settings/Export/ExportTable'

export default {
  components: {
    Icon,
    Team,
    ExportTable
  }
}
</script>
