<template>
  <div>
    <table class="w-full border-collapse text-gray-800">
      <thead>
        <tr>
          <th class="uppercase font-normal text-gray-500 text-left text-xs pb-2">
            Group
          </th>
          <th class="uppercase font-normal text-gray-500 text-left text-xs pb-2">
            Download
          </th>
        </tr>
      </thead>

      <tbody class="text-sm">
        <tr
          v-for="(provisionalGroup, key) in provisionalGroups"
          :key="key"
        >
          <td class="border-t py-4">
            {{ provisionalGroup.name }}
          </td>
          <td class="border-t py-4">
            <ExportButton
              :id="provisionalGroup.slug"
              type="provisional-groups"
              :label="provisionalGroup.name"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ExportButton from '@components/ExportButton'

import { mapGetters } from 'vuex'

export default {
  components: {
    ExportButton
  },

  computed: {
    ...mapGetters({
      provisionalGroups: 'organisations/provisionalGroups'
    })
  }
}
</script>
