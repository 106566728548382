<template>
  <div>
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
        <BasePageHeading>Settings</BasePageHeading>
      </div>
    </BasePageHeader>

    <BaseTabBar>
      <BaseTabs>
        <BaseTab
          v-for="tab in tabs"
          :key="tab.routeName"
          :to="{ name: tab.routeName }"
          :active="tab.routeName === routeName"
        >
          {{ tab.label }}
        </BaseTab>
      </BaseTabs>
    </BaseTabBar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      provisionalGroups: 'organisations/provisionalGroups',
      organisationName: 'organisations/name',
      isPAYG: 'organisations/isPAYG',
      paygSubscription: 'subscriptions/paygSubscription'
    }),

    /**
     * @return {string}
     */
    routeName() {
      return this.$route.name
    },

    /**
     * @return {Array}
     */
    breadcrumbs() {
      return [
        {
          name: this.organisationName
        }
      ]
    },

    /**
     * @return {Array}
     */
    tabs() {
      let tabs = [
        {
          label: 'Profile',
          routeName: 'settings-profile'
        }
      ]

      if (!this.$usesFeature('disable-new-subscriptions')) {
        if (this.isPAYG) {
          tabs.push({
            label: 'Plan',
            routeName: 'settings-payg-plan'
          })
        } else if (!this.isPAYG) {
          tabs.push({
            label: 'Plan',
            routeName: 'settings-plan'
          })
        }
      }

      if (this.$usesFeature('attempts-usage') && !this.isPAYG) {
        tabs.push({
          label: 'Usage',
          routeName: 'settings-usage'
        })
      }

      if (this.isPAYG && this.paygSubscription) {
        tabs.push({
          label: 'Usage',
          routeName: 'settings-payg-usage'
        })
      }

      tabs.push({
        label: 'Billing',
        routeName: 'settings-billing'
      })
      tabs.push({
        label: 'Team',
        routeName: 'settings-team'
      })

      if (this.provisionalGroups.length > 0) {
        tabs.push({
          label: 'Exports',
          routeName: 'settings-exports'
        })
      }

      if (this.$usesFeature('candidate-wizard')) {
        tabs.push({
          label: 'Pipeline',
          routeName: 'settings-pipeline'
        })
      }

      if (this.$usesFeature('integrations')) {
        tabs.push({
          label: 'Integrations',
          routeName: 'settings-integrations'
        })
      }
      tabs.push({
        label: 'API',
        routeName: 'settings-api'
      })
      tabs.push({
        label: 'Webhooks',
        routeName: 'settings-webhooks'
      })

      if (this.$usesFeature('custom-logo')) {
        tabs.push({
          label: 'Branding',
          routeName: 'settings-branding'
        })
      }

      if (this.$usesFeature('faqs')) {
        tabs.push({
          label: 'FAQs',
          routeName: 'settings-faqs'
        })
      }

      return tabs
    }
  }
}
</script>
