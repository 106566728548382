import { pickedApiRequest } from './request'

export default {
  /**
   * List members
   *
   * @param {string} type
   * @param {string} format
   * @return {Object}
   */
  download (type, format, jobId) {
    return pickedApiRequest.get('assessment/export/' + type, {
      responseType: 'arraybuffer',
      query: {
        format,
        job_id: jobId
      }
    })
  },

  /**
   * @param {string} candidateId
   * @return {Object}
   */
  candidateProfile (candidateId) {
    return pickedApiRequest.get('assessment/export/candidates/' + candidateId, {
      responseType: 'arraybuffer'
    })
  }
}
